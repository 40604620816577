import { CalmTheme } from '@calm-web/design-system';

const CARD_GRID_GAP = {
	mobile: 16,
	desktop: 24,
	desktopLarge: 26,
};

export const getCellSpacing = (winWidth: number) => {
	if (winWidth >= CalmTheme.breakpoints.desktopLarge) {
		return CARD_GRID_GAP.desktopLarge;
	}
	if (winWidth >= CalmTheme.breakpoints.desktop) {
		return CARD_GRID_GAP.desktop;
	}
	return CARD_GRID_GAP.mobile;
};

const MAX_BANNER_CARD_WIDTH = 474;
const EFFECTIVE_MAX_BANNER_CARD_WIDTH = MAX_BANNER_CARD_WIDTH + CARD_GRID_GAP.desktopLarge * 0.5;

export const getNumBannerCardsToShow = (winWidth: number) => {
	if (winWidth >= CalmTheme.breakpoints.desktopLarge) {
		return Math.max(winWidth / EFFECTIVE_MAX_BANNER_CARD_WIDTH, 4);
	}
	if (winWidth >= CalmTheme.breakpoints.desktop) {
		return 3;
	}
	if (winWidth >= CalmTheme.breakpoints.tablet) {
		return 2.5;
	}
	return 1.5;
};

const MAX_CAROUSEL_CARD_WIDTH = 316;
const EFFECTIVE_MAX_CAROUSEL_CARD_WIDTH = MAX_CAROUSEL_CARD_WIDTH + CARD_GRID_GAP.desktopLarge * 0.5;

export const getNumCarouselCardsToShow = (winWidth: number) => {
	if (winWidth >= CalmTheme.breakpoints.desktopLarge) {
		return Math.max(winWidth / EFFECTIVE_MAX_CAROUSEL_CARD_WIDTH, 6);
	}
	if (winWidth >= CalmTheme.breakpoints.desktop) {
		return 4;
	}
	if (winWidth >= CalmTheme.breakpoints.tablet) {
		return 3.5;
	}
	return 2.5;
};

export const getSlideOffset = (index: number, currentSlideIndex: number, cellSpacing: number): string => {
	const currIndex = index - currentSlideIndex;
	return `calc(${currIndex * 100}% + ${cellSpacing * currIndex}px)`;
};

const getSliderWidth = (winWidth: number): number => {
	const sidebarWidth = 240;

	return winWidth >= CalmTheme.breakpoints.tablet ? winWidth - sidebarWidth : winWidth;
};

export type CardSize = 'banner' | 'default';

export const getSlideDimensions = (
	winWidth: number,
	cardSize: CardSize,
	numCardsToShow: number,
	aspectRatio: number,
): [number, number] => {
	const sliderWidth = getSliderWidth(winWidth);

	const width = sliderWidth / numCardsToShow;

	/*
	 ** Adjust the height of the cards based on how many cards are shown and the size of the screen
	 ** Considers responsive design
	 */
	let height = width * aspectRatio + 32;
	if (numCardsToShow >= 4 && cardSize === 'banner') {
		if (winWidth >= CalmTheme.breakpoints.desktopLarge) height = width * aspectRatio - 115;
	} else if (numCardsToShow === 3) {
		if (winWidth >= CalmTheme.breakpoints.desktop && winWidth <= CalmTheme.breakpoints.desktopLarge)
			height = width * aspectRatio - 50;
		else height = width * aspectRatio - 115;
	} else if (numCardsToShow === 3.5) {
		height = width * aspectRatio + 65;
	} else if (numCardsToShow === 2.5) {
		height = width * aspectRatio - 15;
	} else if (numCardsToShow === 1.5) {
		if (winWidth <= CalmTheme.breakpoints.mobileLarge) height = width * aspectRatio - 75;
		else height = width * aspectRatio - 150;
	}

	return [width, height];
};
